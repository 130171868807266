<template>
  <v-container fluid class="visit-listing white-background pb-0">
    <v-tabs
      v-if="false"
      active-class="custom-tab-active"
      v-model="visitTab"
      background-color="transparent"
      color="cyan"
      class="custom-tab-transparent tab-sticky"
    >
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#all"
      >
        <template v-if="counts.all > 0">
          <v-badge bordered color="orange darken-4" :content="counts.all">
            All
          </v-badge>
        </template>
        <template v-else>All</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#overdue"
      >
        <template v-if="counts.overdue > 0">
          <v-badge bordered color="orange darken-4" :content="counts.overdue">
            Overdue
          </v-badge>
        </template>
        <template v-else>Overdue</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#today"
      >
        <template v-if="counts.today > 0">
          <v-badge bordered color="orange darken-4" :content="counts.today">
            Today
          </v-badge>
        </template>
        <template v-else>Today</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#tomorrow"
      >
        <template v-if="counts.tomorrow > 0">
          <v-badge bordered color="orange darken-4" :content="counts.tomorrow">
            Tomorrow
          </v-badge>
        </template>
        <template v-else>Tomorrow</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#this-week"
      >
        <template v-if="counts.this_week > 0">
          <v-badge bordered color="orange darken-4" :content="counts.this_week">
            This Week
          </v-badge>
        </template>
        <template v-else>This Week</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#upcomming"
      >
        <template v-if="counts.upcomming > 0">
          <v-badge bordered color="orange darken-4" :content="counts.upcomming">
            Upcoming
          </v-badge>
        </template>
        <template v-else>Upcoming</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#completed"
      >
        <template v-if="counts.completed > 0">
          <v-badge bordered color="orange darken-4" :content="counts.completed">
            Completed
          </v-badge>
        </template>
        <template v-else>Completed</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#cancelled"
      >
        <template v-if="counts.cancelled > 0">
          <v-badge bordered color="orange darken-4" :content="counts.cancelled">
            Cancelled
          </v-badge>
        </template>
        <template v-else>Cancelled</template>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="visitTab">
      <v-tab-item value="all">
        <VisitListingTemplate
          v-if="visitTab == 'all'"
          :key="uniqueTabId"
          :is-admin="isAdmin"
          :visitType="visitTab"
          v-on:update-loading="(param) => (pageLoading = param)"
        ></VisitListingTemplate>
      </v-tab-item>
      <v-tab-item value="overdue">
        <VisitListingTemplate
          v-if="visitTab == 'overdue'"
          :key="uniqueTabId"
          :is-admin="isAdmin"
          :visitType="visitTab"
          v-on:update-loading="(param) => (pageLoading = param)"
        ></VisitListingTemplate>
      </v-tab-item>
      <v-tab-item value="today">
        <VisitListingTemplate
          v-if="visitTab == 'today'"
          :key="uniqueTabId"
          :is-admin="isAdmin"
          visitType="today"
          v-on:update-loading="(param) => (pageLoading = param)"
        ></VisitListingTemplate>
      </v-tab-item>
      <v-tab-item value="tomorrow">
        <VisitListingTemplate
          v-if="visitTab == 'tomorrow'"
          :key="uniqueTabId"
          :is-admin="isAdmin"
          visitType="tomorrow"
          v-on:update-loading="(param) => (pageLoading = param)"
        ></VisitListingTemplate>
      </v-tab-item>
      <v-tab-item value="this-week">
        <VisitListingTemplate
          v-if="visitTab == 'this-week'"
          :key="uniqueTabId"
          :is-admin="isAdmin"
          visitType="this-week"
          v-on:update-loading="(param) => (pageLoading = param)"
        ></VisitListingTemplate>
      </v-tab-item>
      <v-tab-item value="upcomming">
        <VisitListingTemplate
          v-if="visitTab == 'upcomming'"
          :key="uniqueTabId"
          :is-admin="isAdmin"
          visitType="upcomming"
          v-on:update-loading="(param) => (pageLoading = param)"
        ></VisitListingTemplate>
      </v-tab-item>
      <v-tab-item value="completed">
        <VisitListingTemplate
          v-if="visitTab == 'completed'"
          :key="uniqueTabId"
          :is-admin="isAdmin"
          visitType="completed"
          v-on:update-loading="(param) => (pageLoading = param)"
        ></VisitListingTemplate>
      </v-tab-item>
      <v-tab-item value="cancelled">
        <VisitListingTemplate
          v-if="visitTab == 'cancelled'"
          :key="uniqueTabId"
          :is-admin="isAdmin"
          visitType="cancelled"
          v-on:update-loading="(param) => (pageLoading = param)"
        ></VisitListingTemplate>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";

export default {
  name: "visit",
  mounted() {
    const route_name = this.$route.name;
    const indexd = route_name.indexOf("admin");
    if (indexd >= 0) {
      this.isAdmin = true;
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Visit" },
      { title: this.$route?.meta?.title },
    ]);
  },
  watch: {
    $route() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Visit" },
        { title: this.$route?.meta?.title },
      ]);
    },
  },
  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, []);
  },
  data() {
    return {
      isAdmin: false,
      pageLoading: false,
      uniqueTabId: Number(new Date()),
      // visitTab: null,
      counts: {
        all: 0,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        this_week: 0,
        upcomming: 0,
        completed: 0,
      },
    };
  },
  methods: {
    getVisitCounts(countObject) {
      const _this = this;
      if (_this.lodash.isEmpty(countObject) === false) {
        _this.counts = {
          all: _this.lodash.toSafeInteger(countObject.all),
          overdue: _this.lodash.toSafeInteger(countObject.overdue),
          today: _this.lodash.toSafeInteger(countObject.today),
          tomorrow: _this.lodash.toSafeInteger(countObject.tomorrow),
          this_week: _this.lodash.toSafeInteger(countObject.thisWeek),
          upcomming: _this.lodash.toSafeInteger(countObject.upcomming),
          completed: _this.lodash.toSafeInteger(countObject.completed),
        };
      }
    },
  },
  components: {
    VisitListingTemplate,
  },
  created() {
    if (this.$route.query && this.$route.query.tab) {
      this.visitTab = this.$route.query.tab;
    }
  },
  computed: {
    visitTab: {
      set(val) {
        this.uniqueTabId = Number(new Date());
        let query = { ...this.$route.query };
        query.tab = val;
        query.page = 1;
        if (val != this.visitTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "all";
      },
    },
  },
};
</script>
